/* ReactQuillStyles.css */

/* dark */
.custom-quill-dark .ql-toolbar {
    background-color:#374151;
}
  
.custom-quill-dark .ql-toolbar .ql-stroke {
    stroke:#E5E7EB;
}

.custom-quill-dark .ql-toolbar .ql-picker .ql-stroke {
    stroke:#E5E7EB;
}

.custom-quill-dark .ql-toolbar .ql-picker-label::before {
    color:#E5E7EB;
}
  
.custom-quill-dark .ql-container {
    border-color:#F3F4F6; /* Replace with your desired color */
}

/* light */
.custom-quill-light .ql-toolbar {
    background-color:#E5E7EB;
}
  
.custom-quill-light .ql-toolbar .ql-stroke {
    stroke:#374151;
}

.custom-quill-light .ql-toolbar .ql-picker .ql-stroke {
    stroke:#374151;
}

.custom-quill-light .ql-toolbar .ql-picker-label::before {
    color:#374151;
}

.custom-quill-light .ql-container {
    border-color:#E5E7EB; /* Replace with your desired color */
}

